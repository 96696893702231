// import './App.css';
import { 
  Accordion, 
  AccordionPanel, 
  Box,
  Main, 
  Page, 
  PageContent,
  Card,
  CardBody,
  Markdown,
  // Text,
  // ResponsiveContext
 } from 'grommet';

import LeagueCard from './LeagueCard';

const leagues = [
  { name: "Josh",
    id: '1661561268',
    team: 1
  },
  {
    name: 'Luke',
    id: '286565',
    team: 7
  },
  {
    name: 'Boozie',
    id: '319397',
    team: 11
  }
];

const leaguePanel = league => {
  return (
    <AccordionPanel label={league.name}>
      <Box pad="medium" margin="small">
        <LeagueCard ownerName={league.name} leagueID={league.id} teamID={league.team}/>
      </Box> 
    </AccordionPanel>
  )
}

function App() {
  // const size = React.useContext(ResponsiveContext)

  return (
    <div className="App">
      {/* TODO - add nav bar for each  team */}
      {/* TODO - add "add league" to navbar */}
      <Main pad="large">
        {/* <Text>Size is: {size}</Text> */}
        <Page kind="narrow">
            <PageContent>
              <Card>
                <CardBody pad='medium'>
                  <Markdown>Looking for the Fantasy Pals Elimination League? Head over to the [FPEL Notion page](https://bit.ly/fantasy-pals)</Markdown>
                </CardBody>
              </Card>
              <Accordion multiple={true} pad="medium">
                {leagues.map(league => leaguePanel(league))}
              </Accordion>
          </PageContent>
        </Page>
      </Main>
    </div>
  );
}

export default App;
