import {
  Button, 
  Table, 
  TableHeader, 
  TableBody, 
  TableRow, 
  TableCell
} from 'grommet';
import { useState } from 'react';

const sum = (items, prop) => {
  return items.reduce((a, b) => {
      return a + b[prop];
  }, 0);
};

const playerRow = player => {
  return (
      <TableRow>
          <TableCell scope="row">
              {`${player.playerPoolEntry.player.firstName[0]}. ${player.playerPoolEntry.player.lastName}`}
          </TableCell>
          <TableCell>{Math.round(player.playerPoolEntry.appliedStatTotal * 100)/100}</TableCell>
      </TableRow>
  )
};

const rosterRows = team => {
  // todo Sort by position
  if (team && team.rosterForCurrentScoringPeriod) {
    return team.rosterForCurrentScoringPeriod.entries
              .filter(entry => entry.lineupSlotId !== 20 && entry.lineupSlotId !== 21)
              .sort((a ,b) => a.lineupSlotId - b.lineupSlotId)
              .map(plyr => playerRow(plyr));
  } else {
    return (
      <TableRow>
        <TableCell>...loading</TableCell>
        <TableCell>...loading</TableCell>
      </TableRow>
    )
  }       
}

const benchRows = team => {
  // todo Sort by position
    if (team && team.rosterForCurrentScoringPeriod) {
      return team.rosterForCurrentScoringPeriod.entries
                .filter(entry => entry.lineupSlotId === 20 || entry.lineupSlotId === 21)
                .sort((a ,b) => a.lineupSlotId - b.lineupSlotId)
                .map(plyr => playerRow(plyr));
    } else {
      return (
        <TableRow>
          <TableCell>...loading</TableCell>
          <TableCell>...loading</TableCell>
        </TableRow>
      )
    }
}

const totalScore = team => {
  if (team && team.rosterForCurrentScoringPeriod) {
    const starters = team.rosterForCurrentScoringPeriod.entries
                        .filter(entry => entry.lineupSlotId !== 20 && entry.lineupSlotId !== 21)
                        .map(player => player.playerPoolEntry);
    return sum(starters, "appliedStatTotal");
  } else {
    return '...loading'
  }
}

export default function RosterTable(props) {  
  const [showBench, setShowBench] = useState(false);

  return (
      <Table>
          <TableHeader>
              <TableRow>
                  <TableCell scope="col" border="bottom">
                  </TableCell>
                  <TableCell><strong>{Math.round(totalScore(props.team) * 100) / 100}</strong></TableCell>
              </TableRow>
          </TableHeader>
          <TableBody>
              {rosterRows(props.team)}
              <TableRow>
                <TableCell>
                  <Button primary label="Bench" onClick={() => setShowBench(!showBench)}></Button>
                </TableCell>
              </TableRow>
              {showBench ? benchRows(props.team) : null}
          </TableBody>
      </Table>
  )
};